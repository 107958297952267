import { VENDOR_LISTS } from './vendor-collaboration';

// invitation flag feature
export const ENABLE_SHIFT_TIME_RECEPTION = false;
export const ENABLE_ANNOUNCEMENT_MODE = true;
export const ENABLE_PARTNER_MODE = true;
export const ENABLE_GUEST_LIST = true;

// Giftcard flag feature
export const ENABLE_SEND_GIFT = true;
export const ENABLE_BANK_TRANSFER = true;
export const ENABLE_GIFT_CONFIRMATION = true;

// generator link flag feature
export const DISABLE_CUSTOM_LINK = false;
export const DISABLE_GENERATOR_INVITATION = false;

// qrcode flag feature
export const DISABLED_QR_GENERATOR_IN_PRODUCTION = true;
export const ENABLE_QR_INVITATION = false;
export const ENABLE_QR_NAME_VALIDATION = false;

// Global feature invitation
export const DISABLED_MUSIC_IN_DEVELOPMENT = true;
export const MUSLIM_INVITATION = false;
export const DEFAULT_LANGUAGE = 'en'; // change to `en` if you want to set default to English
export const ENABLE_MULTI_LANGUAGE = false;
export const ENABLE_LIVE_STREAMING = false;
export const ENABLE_VIDEO_PREWEDDING = false;
export const ENABLE_GIFT_CARD = true;
export const ENABLE_DARKMODE_NOTIFICATION = true;
export const ENABLE_MUSIC_BACKSOUND = true;
export const ENABLE_LOVE_STORY = true;
export const ENABLE_RSVP = true;
export const ENABLE_GUEST_PERSONALIZATION = true;
export const ENABLE_COVID_PROTOCOL = false;
export const ENABLE_COLLABORATION = false;
export const SELECTED_VENDOR_COLLABORATION = VENDOR_LISTS['aksara']; // change 'aksara' to any of the vendor inside VENDOR_LISTS if want to use a new vendor, just copy and add under the last vendor
export const ENABLE_INSTAGRAM_FILTER = true;
